html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-size: 0.945rem;
  font-weight: 300;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  background: #fff;
  color: #666;
}
.uk-iwin-masterfile {
  background: url(/assets/home.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  color: #cfcfcf;
  height: auto;
  min-height: 100vh;
}
.uk-main-subheading {
  font-size: 1.6rem;
  text-transform: uppercase;
  color: #c0c0c0;
}

.uk-table tbody tr:hover {
  background: #f1f1f1;
  cursor: pointer;
}
.update {
  background-color: #f9f0b4 !important;
}
.uk-icon-button.uk-icon-link {
  border-radius: 50%;
  background: #c33838;
  color: #fff;
}
.pulse {
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #c33838;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(195, 56, 56, 0.4);
  animation: pulse 2s infinite;
}
.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(195, 56, 56, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(195, 56, 56, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(195, 56, 56, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(195, 56, 56, 0.4);
    box-shadow: 0 0 0 0 rgba(195, 56, 56, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(195, 56, 56, 0);
    box-shadow: 0 0 0 10px rgba(195, 56, 56, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(195, 56, 56, 0);
    box-shadow: 0 0 0 0 rgba(195, 56, 56, 0);
  }
}
.uk-icon-facebook:hover > span,
.uk-icon-facebook:active > span {
  color: rgb(59, 89, 152);
}
.uk-icon-google-plus:hover > span,
.uk-icon-google-plus:active > span {
  color: rgb(219, 68, 55);
}
.uk-icon-twitter:hover > span,
.uk-icon-twitter:active > span {
  color: rgb(29, 161, 242);
}
.uk-accordion-title::before {
  transform: rotate(90deg);
  background-color: #6b6b73;
  content: " ";
  position: absolute;
  top: 10px;
  right: 15px;
  height: 2px;
  width: 18px;
  transition: transform 0.2s ease;
}
html[dir="rtl"] .uk-accordion-title::before {
  left: 5px;
  right: auto;
}
.uk-accordion > li.uk-open > .uk-accordion-title::before {
  transform: rotate(45deg);
}
.uk-accordion-title::after {
  content: " ";
  position: absolute;
  top: 10px;
  right: 15px;
  height: 2px;
  width: 18px;
  background-color: #6b6b73;
  transition: transform 0.2s ease;
}
.uk-accordion > li.uk-open > .uk-accordion-title::after {
  transform: rotate(-45deg);
}
html[dir="rtl"] .uk-accordion-title::after {
  left: 15px;
  right: auto;
}
html[dir="rtl"] .uk-modal-close-full {
  left: 0;
  right: auto;
}
.uk-hover-link {
  cursor: pointer;
}
.uk-hover-link:hover {
  background: #d9d9d9;
}

/* header nav-bar ------------------------------------------------ */
header {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
}
.uk-navbar > .uk-container {
  width: 100vw;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.uk-navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.uk-navbar-expand > .uk-container,
.uk-navbar-expand > .uk-container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.uk-navbar-bottom {
  background-color: #fff;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  position: relative;
  z-index: 1030;
  padding-top: 0;
  padding-bottom: 0;
}
.uk-navbar-bottom .uk-container {
  height: 50px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}
/* Landing page transparent topbar */
.uk-navbar.uk-navbar-transparent > div > ul > li > a,
ul.uk-dropdown-nav > li > a {
  text-align: center;
  text-transform: uppercase;
}
.mobile-menu-align .uk-dropdown {
  background-color: rgba(248, 248, 248, 0.96);
}
/* off-canvas */
.uk-offcanvas-container,
.uk-offcanvas-page {
  overflow-x: hidden;
  height: 100vh;
}
.uk-subnav-divider > :nth-child(n + 2):not(.uk-first-column)::before {
  content: "";
  height: 1.5em;
  margin-left: -5px;
  margin-right: -5px;
  border-left: 1px solid #e5e5e5;
}
#profile-card {
  background-color: rgba(0, 0, 0, 0.4);
}
main {
  margin-top: 0px;
  padding-bottom: 0px;
  min-height: calc(100vh);
}
#landingLayout {
  margin-top: 0;
  padding-top: 0;
  height: 100vh;
}
#layout {
  margin-top: 0px;
  padding-top: 80px;
  height: 100vh;
  background-size: cover;
  background: url("/assets/images/bg.webp") no-repeat right top fixed;
}
.uk-card {
  border: 0;
  color: #1b3244;
  height: 100%;
  overflow: hidden;
}
.uk-card-iw {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: solid 1px #eee;
}
a > .uk-card-iw:hover {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.4);
}
.uk-card-transparent {
  background: transparent !important;
}
.uk-card-news {
  border: 0;
}
.uk-card-news > .uk-news-header {
  margin: auto auto 20px auto;
}
.uk-card-news > .uk-news-header::after {
  display: block;
  content: "";
  height: 1px;
  background: #ccc;
  position: relative;
  bottom: 5px;
  top: 10px;
}
.uk-card-news > .uk-news-header > .uk-news-category {
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-right: 20px;
  display: inline-block;
}
.uk-card-news > .uk-news-header > .uk-news-date {
  font-size: 0.8rem;
  font-weight: 300;
  text-transform: uppercase;
  display: inline;
}
.uk-card-news > .uk-news-header > .uk-news-title {
  margin: 20px 0 20px 0;
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 500;
  text-transform: none;
}
.uk-card-news > .uk-news-header > .uk-news-author {
  font-size: 0.8rem;
  font-weight: 300;
  text-transform: none;
}
.uk-card-news > .uk-news-header > .uk-news-author > .uk-news-time-read {
  float: right;
}
.uk-card-news > .uk-news-image {
  margin: 10px auto 20px auto;
}
.uk-card-news > .uk-news-image > .uk-news-image-cropped {
  max-height: 500px;
  overflow: hidden;
}
.uk-card-news > .uk-news-image > .uk-news-image-cropped > img {
  margin-top: -100px;
}
.uk-card-news > .uk-news-image > .uk-news-image-legend::before {
  display: block;
  content: "";
  height: 1px;
  width: 10%;
  background: rgb(105, 40, 40);
  position: relative;
  top: -3px;
}
.uk-card-news > .uk-news-image > .uk-news-image-legend {
  font-size: 0.6rem;
  font-weight: 400;
  font-style: italic;
  text-transform: none;
  margin-top: 13px;
}
.uk-card-news > .uk-news-cropped {
  overflow: hidden;
  position: relative;
  line-height: 1.2em;
  max-height: 16.8em;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
}
.uk-card-news > .uk-news-cropped:before {
  content: "...";
  position: absolute;
  right: 0;
  bottom: 0;
}
.uk-card-news > .uk-news-cropped:after {
  content: "";
  position: absolute;
  right: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: white;
}
.uk-modal-dialog {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: 0;
  color: #1b3244;
  overflow: hidden;
}
.uk-button-table-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  width: 34px;
  height: 34px;
}
.uk-badge {
  border-radius: 5px !important;
  margin-left: 5px !important;
}

/* uk-text styling ----------------------------------------------- */
.uk-text {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-size: 0.945rem;
  font-weight: 400;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  text-transform: none;
}
.uk-text-300 {
  font-weight: 300;
}
.uk-text-iwin {
  font-family: "Roboto Mono", sans-serif;
  font-weight: 400;
}
.uk-text-number {
  font-family: "Roboto Mono", sans-serif;
}
.uk-text-statistic {
  font-family: "Roboto Mono", sans-serif;
  font-weight: 400;
  font-size: 3em;
  color: #fff;
}
.uk-text-statement {
  font-size: 2rem;
  font-weight: 300;
  font-style: italic;
}
.uk-text-affirm {
  padding: 30px 0;
  font-size: 2rem;
  font-weight: 400;
}
.uk-section-branded {
  background: url("/assets/branding-img-bw-400x400.png") no-repeat -24px 50px;
}
html[dir="rtl"] .uk-section-branded {
  background: url("/assets/branding-img-bw-400x400.png") no-repeat
    calc(100% + 24px) 50px;
}
.uk-section-header {
  text-align: center;
  position: relative;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 3rem;
}
.uk-light .uk-section-header {
  color: #ffffff;
}
.uk-light .uk-section-subheader {
  color: #f9f9f9;
}
/* .uk-section-header::after {
  display: block;
  content: "";
  width: 100%;
  height: 1px;
  background: black;
  position: absolute;
  bottom: 5;
  left: 0;
} */
.uk-section-subheader,
.uk-card-header {
  text-align: center;
  position: relative;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 1.5rem;
}
/* .uk-section-subheader::before {
  display: block;
  content: "";
  padding-top: 20px;
}
.uk-section-subheader::after {
  display: block;
  content: "";
  width: 100%;
  height: 1px;
  background: black;
  position: absolute;
  bottom: 5;
  left: 0;
} */

.uk-highlight > span > em {
  color: #5095f0;
}

/* themes user --------------------------------------------------- */
.theme-person .uk-card-statistic {
  background: rgb(242, 100, 70);
  background: linear-gradient(90deg, rgba(242, 100, 70, 0.7) 0%, #f26446 100%);
}
.theme-corporate .uk-card-statistic {
  background: rgba(93, 171, 248);
  background: linear-gradient(90deg, rgba(93, 171, 248, 0.7) 0%, #5dabf8 100%);
}

/* toggle switch ------------------------------------------------- */
.slide-toggle-label {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
}
.slide-toggle-label .slide-toggle-content {
  text-transform: uppercase;
}
.slide-toggle-label .slide-toggle-content:first-child {
  margin-right: 18px;
}
.slide-toggle-label .slide-toggle-content:last-child {
  margin-left: 18px;
}
.slide-toggle-label .slide-toggle-content {
  color: #7997af;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.slide-toggle-label .slide-toggle-content.slide-toggle-content-left.active,
.person {
  color: #f26446;
}
.slide-toggle-label .slide-toggle-content.slide-toggle-content-right.active,
.corporate {
  color: #5dabf8;
}
.person-background {
  background: #f26446;
}
.corporate-background {
  background: #5dabf8;
}
.slide-toggle-label.slide-toggle-label--checked .slide-toggle-bar {
  background-color: rgba(93, 171, 248, 0.4);
}
.slide-toggle-label .slide-toggle-bar {
  position: relative;
  width: 34px;
  height: 14px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background-color: rgba(242, 100, 70, 0.4);
  margin-top: 2px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.slide-toggle-label .slide-toggle-bar input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-checkbox-input,
input[type="checkbox"] {
  width: 15px;
  position: relative;
}
.slide-toggle-label.slide-toggle-label--checked
  .slide-toggle-bar
  .slide-toggle-thumb {
  background-color: #5dabf8;
}
.slide-toggle-label.slide-toggle-label--checked
  .slide-toggle-bar
  .slide-toggle-thumb {
  -webkit-transform: translate3d(16px, 0, 0);
  transform: translate3d(16px, 0, 0);
}
.slide-toggle-label .slide-toggle-bar .slide-toggle-thumb {
  height: 20px;
  width: 20px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  top: -3px;
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  background-color: #f26446;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}
/* input styling ------------------------------------------------- */
input[type="date"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}
input[type="date"]::-webkit-clear-button {
  -webkit-appearance: none; /* turn off default browser styling */
  display: none; /* Hide the button */
}
/* input datepicker styling -------------------------------------- */
.react-datepicker-wrapper {
  width: 100% !important;
}
div[data-reach-popover] {
  border: none !important;
}
ul[data-reach-combobox-list] {
  border: solid 1px #e5e5e5;
}
